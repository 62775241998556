*,
*::after,
*::before {
  font-family: "Raleway", sans-serif;
  outline: none;
  box-sizing: border-box;
}

:root {
  font-size: 18px;
  --primary: #434365;
  --accent-gold: #d6b714;
  --accent-copper: #cb9c7a;
  --background-dark: #1e1e1e;
  --background-light: #f8f8f8;
  --text: #383838;
  --error: #f1684e;
  --success: #29a19c;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--background-light);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
